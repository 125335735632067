<template>
  <div>
    <form class="form">
      <div>
        <!-- <label>Bank Name</label> -->
        <div class="input-group input-group-lg mb-3">
          <input
            id="input"
            type="text"
            placeholder="Name"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
      </div>

      <div>
        <!-- <label>Account Number</label> -->
        <div class="input-group input-group-lg mb-3">
          <input
            type="text"
            id="input2"
            placeholder="Email"
            class="form-control mt-4"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
      </div>

      <div>
        <!-- <label>Account Name</label> -->
        <div class="input-group input-group-lg mb-3">
          <input
            type="text"
            id="input3"
            placeholder="Phone number"
            class="form-control mt-4"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
          />
        </div>
      </div>
      <Button id="btn" @click="save" buttonText="Create" />
    </form>
  </div>
</template>

<script>
import Button from "../../../../components/ReusableUI/button.vue";
export default {
  name: "Bank",

  components: {
    Button,
  },
};
</script>

<style>
.payment-container {
  padding: 0% 5%;
}

form {
  padding-top: 20px;
}

#input {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
  margin-right: 0px;
}

#input2 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#input3 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}
</style>
