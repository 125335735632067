<template>
  <div class="payment-container">
    <h4>My Business</h4>
    <div class="nav-options">
      <a
        href="#"
        @click="bankAccount()"
        class="link target"
        id="nav-anchor"
        active-class="active-amchor-tag"
      >
        Bank Account
      </a>

      <a
        href="#"
        @click="transave"
        class="link target"
        id="nav-anchor"
        active-class="active-amchor-tag"
      >
        Transave
      </a>
    </div>

    <div v-show="bank">
      <Bank />
    </div>

    <div v-show="trans">
      <Transave />
    </div>
  </div>
</template>

<script>
import Bank from "../../../VendorPages/VendorComponents/DashboardPages/BankPayment.vue";
import Transave from "../../../VendorPages/VendorComponents/DashboardPages/Transave.vue";

export default {
  components: {
    Bank,
    Transave,
  },

  data() {
    return {
      trans: false,
      bank: true,
    };
  },

  methods: {
    transave() {
      (this.trans = true), (this.bank = false);
    },

    bankAccount() {
      (this.trans = false), (this.bank = true);
    },
  },
};
</script>

<style>
.payment-container {
  padding: 0% 3%;
}

a:focus {
  background-color: rgba(243, 237, 237, 1);
  border-radius: 4px;
  color: rgba(133, 77, 39, 1);
}

:target {
  color: red;
}

#nav-anchor {
  text-decoration: none;
  font-size: 14px;
  color: rgba(133, 77, 39, 1);
  cursor: pointer;
}
.link {
  text-decoration: none;
  font-size: 14px;
  padding: 1% 5%;
  color: rgba(133, 77, 39, 1);
}

.payment-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.nav-options {
  display: flex;
  margin-top: 20px;
}

.bank {
  margin-right: 3%;
}

.active-amchor-tag {
  background-color: rgba(243, 237, 237, 1);
  border-radius: 4px;
  color: rgba(133, 77, 39, 1);
}
</style>
